import React from 'react'
import { Link } from 'gatsby'
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import success from "../images/success.svg"

const Success = () => {
    return (
        <Layout>
            <Head title="We received your request" />
            <div className="row blog-index">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Thanks! <span role="img" aria-label="emoji">🐳</span></h1>
                <p>Welcome tou our community! If you didn't download Çember yet, go to app stores and download it now.
                </p>
                <h5><Link to="/en" className="cta-blue-button">Go back to home page</Link></h5>
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={success} alt="Success"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default Success